import React, { useEffect, useRef, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import parse from 'react-html-parser';
import Pagination from "@material-ui/lab/Pagination";
import Editor from 'react-simple-wysiwyg';
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Dropdown, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { AiFillEye } from "react-icons/ai";
import { MdCancel } from "react-icons/md";


export default function AllNews() {
  const date = new Date();
  const fileInputRef = useRef(null);
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [error, setError] = useState("");

  const [newData, setNewData] = useState({
    image: "",
    name: "",
    translatName: "",
    description: "",
    translatDescription: "",
    tag: "",
    isfavorite: false,
    // country: "",
  });
  const [viewData, setViewData] = useState({});

  const columns = [
    {
      dataField: "_id",
      text: "ID",
      // sort: true,
      formatter: (cell, row, i) => {
        return (
          <div className="d-flex align-items-center">
            {(currentpage - 1) * pagesize + i + 1 || "-"}
          </div>
        );
      },
    },
    {
      dataField: "image",
      text: "Image",
      sort: true,
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <div
            className="cursor-pointer"
            //    onClick={() => viewBtnClick(row)}
          >
            {/* <img src={row?.image} alt="loading.." srcset="" /> */}
            <img
              src={
                row?.image
                  ? `${Bucket}${row?.image}`
                  : "https://static.vecteezy.com/system/resources/thumbnails/014/066/812/small/discount-ribbon-banner-icon-for-graphic-design-logo-website-social-media-mobile-app-ui-illustration-vector.jpg"
              }
              alt=""
              width={50}
              height={50}
              className="rounded object-cover"
            />
          </div>
        );
      },
    },
    // {
    //   dataField: "Categories",
    //   text: "Categories",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <div>{cell || "-"}</div>;
    //   },
    // },
    {
      dataField: "Name",
      text: "Name",
      sort: true,
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return <div>{row?.name ? row?.name : "-"}</div>;
      },
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        const descriptionStyle = {
          width: "200px", // Replace with your desired width
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
        };
        return (
          <div
          // onClick={() => viewBtnClick(row)}
          // style={descriptionStyle}
          >
            {/* {row?.description ? parse(row?.description ): "-"} */}
            <a
                title="Edit customer"
                className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
                onClick={() => viewBtnClick(row)}
              >
                View
                </a>
            {/* dangerouslySetInnerHTML={{ __html: row?.description ? row?.description : "-" }} */}
          </div>
        );
      },
    },
    // {
    //   dataField: "Favorite",
    //   text: "Favorite Categories",
    //   sort: true,
    //   headerStyle: {
    //     textAlign: 'center',
    //   },
    //   style: {
    //     textAlign: 'center',
    //   },
    //   formatter: (cell, row) => {
    //     return (
    //       <div>
    //         {row?.isfavorite ? (
    //           <svg
    //             width="16"
    //             height="16"
    //             className="me-2 pointer"
    //             viewBox="0 0 25 23"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M0.255859 7.28189C0.255556 5.5423 0.966817 3.87563 2.22962 2.65689C3.49241 1.43814 5.20069 0.769679 6.97009 0.801894C9.06654 0.79095 11.067 1.6649 12.4635 3.20189C13.8601 1.6649 15.8606 0.79095 17.957 0.801894C19.7264 0.769679 21.4347 1.43814 22.6975 2.65689C23.9603 3.87563 24.6715 5.5423 24.6712 7.28189C24.6712 13.7091 16.884 18.5619 12.4635 22.4019C8.05291 18.5295 0.255859 13.7139 0.255859 7.28189Z"
    //               fill="#ED3628"
    //             />
    //           </svg>
    //         ) : (
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             width="16"
    //             height="16"
    //             fill="currentColor"
    //             className="bi bi-heart me-2 pointer"
    //             viewBox="0 0 16 16"
    //           >
    //             <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z" />
    //           </svg>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "gender",
    //   text: "Gender",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <div>{row?.gender === 0 ? "Male" : "Female"}</div>;
    //   },
    // },
    // {
    //   dataField: "dob",
    //   text: "DOB",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return (
    //       <div>{cell ? moment(cell).format("DD-MM-YYYY") : "-" || "-"}</div>
    //     );
    //   },
    // },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      headerStyle: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return <div>{moment(cell).format("DD-MM-YYYY") || "-"}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      headerStyle: {
        textAlign: 'center',
      },
      formatter: (cell, row) => {
        return (
          <>
            {/* <a
              title="View User"
              className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
              onClick={() => viewBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
                <AiFillEye className="" />
              </span>
            </a> */}
            <div className="d-flex btn-center">
              <a
                title="Edit customer"
                className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
                onClick={() => editBtnClick(row)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>

              <a
                title="Delete customer"
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => {
                  Swal.fire({
                    text: `Are you sure you want to Delete ?`,
                    icon: "warning",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: `Yes, Delete`,
                    confirmButtonColor: "#D72852",
                    cancelButtonColor: "black",
                    cancelButtonText: "No, Cancel",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      onDelete(row?._id);
                    }
                  });
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </div>
          </>
        );
      },
    },
  ];

  const handleChange = (e,) => {
    const { value, name, checked } = e.target;
    if (name === "isfavorite") {
      setNewData({ ...newData, [name]: checked });
    } else {
      setNewData({ ...newData, [name]: value });
    }
  };
  console.log("newData", newData);
  const editBtnClick = (row) => {
    console.log("row123", row);
    setIsEdit(true);
    setModal(true);
    setNewData({
      id: row?._id,
      image: row?.image,
      name: row?.name,
      translatName: row?.translateName,
      description: row?.description,
      translatDescription: row?.translateDescription,
      tag: row?.tag
      // country: row?.country,
    });
  };
  const viewBtnClick = (row) => {
    // setIsEdit(true);
    setViewModal(true);
    setViewData(row);
    setNewData({
      id: row?._id,
      image: row?.image,
      categories: row?.name,
      description: row?.description,
      tag: row?.tag
      // date: moment(row?.date).format("YYYY-MM-DD"),
      // country: row?.country,
    });
  };
  const onDelete = async (Id) => {
    await ApiDelete(`/news/${Id}`)
      .then(() => {
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onUpdate = async () => {
    let body = {
      id: newData?.id,
      // firstName: newData?.firstName,
      image: newData?.image,
      name: newData?.name,
      translateName: newData?.translatName,
      description: newData?.description,
      translateDescription: newData?.translatDescription,
      tag: newData?.tag
    };
    
    console.log("body", body);

    if (
      newData?.image &&
      newData?.name.trim().length > 0 &&
      newData?.description.trim().length > 0 &&
      newData?.tag.trim().length > 0
    ) {
      await ApiPut("/news", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          fetchData(currentpage, pagesize, searching);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (!newData?.image) {
      ErrorToast("Image is Required!");
    } else if (
      !newData?.name ||
      newData?.name.trim().length === 0
    ) {
      ErrorToast("Name is Required!");
    } else if (
      !newData?.description ||
      newData?.description.trim().length === 0
    ) {
      ErrorToast("Description is Required!");
    }else if (
      !newData?.tag ||
      newData?.tag.trim().length === 0
    ) {
      ErrorToast("Tag is Required!");
    }
  };
  const handleSubmit = async () => {
    let body = {
      // firstName: newData?.firstName,
      image: newData?.image,
      name: newData?.name,
      translateName: newData?.translatName,
      description: newData?.description,
      translateDescription: newData?.translatDescription,
      tag: newData?.tag
    };
    // console.log("body", body);
    // return
    if (
      newData?.image &&
      newData?.name.trim().length > 0 &&
      newData?.description.trim().length > 0 &&
      newData?.tag.trim().length > 0
    ) {
      await ApiPost("/news", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          fetchData(currentpage, pagesize, searching);
        })
        .catch((err) => ErrorToast(err?.message));
    } else if (!newData?.image) {
      ErrorToast("Image is Required!");
    } else if (
      !newData?.name ||
      newData?.name.trim().length === 0
    ) {
      ErrorToast("Name is Required!");
    } else if (
      !newData?.description ||
      newData?.description.trim().length === 0
    ) {
      ErrorToast("Description is Required!");
    }else if (
      !newData?.tag ||
      newData?.tag.trim().length === 0
    ) {
      ErrorToast("Tag is Required!");
    }
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  // const imageChange = async (e) => {
  //   let file = e.target.files[0];
  //   let fileURL = URL.createObjectURL(file);
  //   file.fileURL = fileURL;
  //   let formData = new FormData();
  //   formData.append("image", file);
  //   await ApiUpload("admin/image", formData)
  //     .then((res) => {
  //       console.log('res', res)
  //       setNewData({ ...newData, image: res?.data?.data });
  //     })
  //     .catch((err) => console.log("res_blob", err));
  // };
  const imageChange = async (e) => {
    let file = e.target.files[0];
    // if (file.type !== "image/svg+xml") {
      // ErrorToast("Please upload only SVG files.");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      // return;
    // }
    let fileURL = URL.createObjectURL(file);
    let img = new Image();
    img.src = fileURL;

    img.onload = async () => {
      const svgWidth = img.width;
      const svgHeight = img.height;
      // if (svgWidth <= 50 && svgHeight <= 50) {
        let formData = new FormData();
        formData.append("image", file);

        await ApiUpload("admin/image", formData)
          .then((res) => {
            setNewData({ ...newData, image: res?.data?.data });
          })
          .catch((err) => console.log("res_blob", err));
      // } 
      // else {
      //   if (fileInputRef.current) {
      //     fileInputRef.current.value = "";
      //   }
      //   ErrorToast("Please select valid image dimension.(Max Size:50*50)");
      // }
    };
  };

  const handlesearch = (e) => {
    setcurrentpage(1);
    setsearching(e.target.value);
    fetchData(1, pagesize, e.target.value);
  };
  const getCountry = async () => {
    let body = {
      page: 1,
      limit: 50,
      search: "",
    };
    await ApiPost("/get/country", body)
      .then((res) => {
        setCountryData(res?.data?.data?.response);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    await ApiPost("/getNews", body)
      .then((res) => {
        console.log("/workout/get", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
    // getCountry();
  }, []);
  // useEffect(() => {
  //   fetchData(currentpage, pagesize, searching);
  // }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">All News</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
        News - All News      
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              {/* <h3 class="card-label">Video Playlist</h3> */}
              {/* <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Categories"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div> */}
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn1 btn-color  font-weight-bolder btn-sm ms-4"
                onClick={() => {
                  setIsEdit(false);
                  setModal(true);
                  setNewData({
                    image: "",
                    categories: "",
                    description: "",
                    contact: "",
                    date: moment(date).format("YYYY-MM-DD"),
                    isfavorite: false,
                  });
                }}
              >
                Add News
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            {console.log(data, "12344")}
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data || []}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataTable />}
              // filter={filterFactory()}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {!isEdit ? " Add News" : "News Edit Details"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-12">
              {/* <Form.Label>image</Form.Label>
              <Form.Control
                type="image"
                placeholder="Select image"
                label="image"
                id="image"
                required
                name="image"
                onChange={handleChange}
                value={newData.image}
              />
              <span className="errorInput">
              </span>
            </Form.Group> */}
              <Form.Label>Image</Form.Label>
              <input
                ref={fileInputRef}
                type="file"
                className="form-control"
                name="image"
                id="image"
                onChange={imageChange}
              />
              <span className="errorInput"></span>
            </Form.Group>
            {newData?.image && (
              <div className="position-relative w-auto">
                <img
                  src={
                    newData?.image
                      ? `${Bucket}${newData?.image}`
                      : "https://static.vecteezy.com/system/resources/thumbnails/014/066/812/small/discount-ribbon-banner-icon-for-graphic-design-logo-website-social-media-mobile-app-ui-illustration-vector.jpg"
                  }
                  alt="sd"
                  style={{ width: "100px", height: "100px" }}
                  className="rounded-3"
                />
                <span
                  className="imageRemovoIconSpan"
                  onClick={() => {
                    setNewData({ ...newData, image: "" });
                    document.getElementById("image").value = null;
                  }}
                >
                  <MdCancel color="red" fontSize={20} />
                </span>
              </div>
            )}

            <Form.Group className="col-md-12">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Name"
                label="name"
                id="name"
                required
                name="name"
                onChange={handleChange}
                value={newData.name}
              />
              <span className="errorInput">
                {/* {newData.categories?.length > 0 ? "" : errors["categories"]} */}
                {/* {newData.categories?.length > 0 ? "" : ErrorToast("Enter categories")} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12" >
              <Form.Label>Name (AR)</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل الاسم"
                label="translatName"
                id="translatName"
                required
                name="translatName"
                style={{ textAlign: 'right' }}
                onChange={handleChange}
                
                value={newData.translatName}
              />
              <span className="errorInput">
                {/* {newData.categories?.length > 0 ? "" : errors["categories"]} */}
                {/* {newData.categories?.length > 0 ? "" : ErrorToast("Enter categories")} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Tag</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tag"
                label="tag"
                id="tag"
                required
                name="tag"
                onChange={handleChange}
                value={newData.tag}
              />
              <span className="errorInput">
                {/* {newData.categories?.length > 0 ? "" : errors["categories"]} */}
                {/* {newData.categories?.length > 0 ? "" : ErrorToast("Enter categories")} */}
              </span>
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Description</Form.Label>
              <Editor value={newData?.description} onChange={(e)=>{
                setNewData({ ...newData, ['description']: e.target.value })
              }} />
              
              <span className="errorInput">
                {/* {newData.description?.length > 0 ? "" : errors["name"]} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Description (AR)</Form.Label>
              <Editor value={newData?.translatDescription} onChange={(e)=>{
                setNewData({ ...newData, ['translatDescription']: e.target.value })
              }} />
              <span className="errorInput">
                {/* {newData.description?.length > 0 ? "" : errors["name"]} */}
              </span>
            </Form.Group>

            {/* <Form.Group className="col-md-12">
              <Form.Label>Contact No.</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter contact no"
                label="contact"
                id="contact"
                required
                name="contact"
                onChange={handleChange}
                value={newData.contact}
              />
              <span className="errorInput">
             
              </span>
            </Form.Group> */}

            {/* <Form.Group className="col-md-12">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                custom
                id="country"
                required
                className="form-control"
                name="country"
                onChange={handleChange}
                value={newData.country}
              >
                <option value="" disabled>
                  Select country
                </option>
                {countryData?.map((v, i) => {
                  return (
                    <>
                      <option value={v?.countryName}>{v?.countryName}</option>
                    </>
                  );
                })}
              </Form.Control>
            </Form.Group> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button className="btn btn1 btn-color" onClick={onUpdate}>
              Update Changes
            </button>
          ) : (
            <button className="btn btn1 btn-color" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewModal}
        onHide={() => setViewModal(false)}
        centered
        className="modal-width"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <div className="text-center" style={{ marginBottom: "1.75rem" }}>
              <img
                src={
                  viewData?.image
                    ? `${Bucket}${viewData?.image}`
                    : "https://static.vecteezy.com/system/resources/thumbnails/014/066/812/small/discount-ribbon-banner-icon-for-graphic-design-logo-website-social-media-mobile-app-ui-illustration-vector.jpg"
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src =
                    "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png";
                }}
                alt="userImage"
                className=" rounded-pill object-cover"
                height="100px"
                width="100px"
              />
            </div> */}

            <div className="row py-2">
              <div className="col-md-12">{parse(newData?.description) ?? "-"}</div>
            </div>

            {/* <div className="row py-2">
              <div className="col-md-5 fw-bold">Email</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.email ?? "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">DOB</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.dob
                  ? moment(viewData?.createdAt).format("DD-MM-YYYY")
                  : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Gender</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.gender === 0 ? "Male" : "Female"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">About Me</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.aboutMe ? viewData?.aboutMe : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Hobby</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.hobby ? viewData?.hobby : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Relationship Status </div>
              <div className="col-md-7 text-capitalize">
                {viewData?.relationshipStatus
                  ? viewData?.relationshipStatus === 0
                    ? "Married "
                    : viewData?.relationshipStatus === 1
                      ? "Single"
                      : "Relationship"
                  : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Shoe Size</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.ShoeSize ? viewData?.size?.ShoeSize : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Bust</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.Bust ? viewData?.size?.Bust : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Shirt Size</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.ShirtSize ? viewData?.size?.ShirtSize : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Dress</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.Dress ? viewData?.size?.Dress : "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Waist</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.Waist ? viewData?.size?.Waist : "-"}
              </div>
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-color" onClick={() => setViewModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
