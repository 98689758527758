import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
} from "../../../helpers/API/ApiData";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Dropdown, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { AiFillEye } from "react-icons/ai";

export default function History() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const UserIdQuery = params.get('id');
  const date = new Date();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [statusModal, setStatusModal] = useState(false);

  const [newData, setNewData] = useState({
    name: "",
    lastname:'',
    email: '',
    contact: '',
    date: moment(date).format("YYYY-MM-DD"),
    country: "",
  });
  const [viewData, setViewData] = useState({});

  const columns = [
    {
      dataField: "_id",
      text: "ID",
      // sort: true,
      formatter: (cell, row, i) => {
        return <div className="d-flex align-items-center">{(currentpage - 1) * pagesize + i + 1 || "-"}</div>;
      },
    },
    {
      dataField: "title",
      text: "Category Title",
      sort: true,
      formatter: (cell, row) => {
        return (
          <div className="cursor-pointer"
          //    onClick={() => viewBtnClick(row)}
          >

            {Array.isArray(row?.completion)?`Chat - ${row?.chat?.model_responses[0]?.text}`:row?.completion?.category_info?.title  ? row?.completion?.category_info?.title : '-'}
          </div>
        );
      },
    },
    {
      dataField: "name",
      text: "Translate Name",
      sort: true,
      formatter: (cell, row) => {
        return (
            <div className="cursor-pointer"
          //    onClick={() => viewBtnClick(row)}
          >

            {row?.completion?.category_info?.translatedName  ? row?.completion?.category_info?.translatedName : '-'}
          </div>
        );
      },
    },
    // {
    //   dataField: "gender",
    //   text: "Gender",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <div>{row?.gender === 0 ? "Male" : "Female"}</div>;
    //   },
    // },
    // {
    //   dataField: "dob",
    //   text: "DOB",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return (
    //       <div>{cell ? moment(cell).format("DD-MM-YYYY") : "-" || "-"}</div>
    //     );
    //   },
    // },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (cell, row) => {
        return <div>{moment(cell).format("DD-MM-YYYY") || "-"}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {/* <a
              title="View User"
              className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
              onClick={() => viewBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
                <AiFillEye className="" />
              </span>
            </a> */}
            <a
             onClick={() => viewBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger ml-4">
               View Detail
              </span>
            </a>
          </>
        );
      },
    },
  ];

  const handleChange = (e) => {

    const { value, name } = e.target;
    const trimmedValue = value.trim();
    setNewData({ ...newData, [name]: trimmedValue  });
  };
  console.log("newData", newData);
  const editBtnClick = (row) => {
    console.log("row", row);
    setIsEdit(true);
    setModal(true);
    setNewData({
      id: row?._id,
      name: row?.firstName,
      lastname:row?.lastName,
      email: row?.email,
      contact: row?.phoneNumber,
      date: moment(row?.createdAt).format("YYYY-MM-DD"),
      // country: row?.country,
    });
  };
  const viewBtnClick = (row) => {
    // setIsEdit(true);
    setViewModal(true);
    setViewData(row);
    // setNewData({
    //   id: row?._id,
    //   name: row?.eventTypeName,
    //   // date: moment(row?.date).format("YYYY-MM-DD"),
    //   // country: row?.country,
    // });
  };
  const onDelete = async (Id) => {
    await ApiDelete(`/users/${Id}`)
      .then(() => {
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const onUpdate = async () => {
    let body = {
      id: newData?.id,
      firstName: newData?.name,
      lastName:newData?.lastname,
      email: newData?.email,
      phoneNumber: newData?.contact,
      // createdAt: moment(newData?.date).format("MM-DD-YYYY"),
      // country: newData?.country,
    };
    console.log("body", body);
    const contactValue = String(newData?.contact || '').trim();

    if (newData?.name !== null && newData?.name.trim().length > 0 &&  newData?.lastname !== null && newData?.lastname.trim().length > 0 && newData?.email !== null && newData?.email.trim().length > 0 &&    contactValue !== null &&
    contactValue.trim().length > 0
)  {
      body.phoneNumber = contactValue;

    await ApiPut("/users", body)
    .then((res) => {
      SuccessToast(res?.data?.message);
      setModal(false);
      fetchData(currentpage, pagesize, searching);
    })
    .catch((err) => ErrorToast(err?.message));
  }
    else if (!newData?.name || newData?.name.trim().length === 0){
      ErrorToast("FirstName is required")
    }
    else if(!newData?.lastname ||newData?.lastname.trim().length === 0){
      ErrorToast("LastName is required")
    }
    else if(!newData?.email ||newData?.email.trim().length === 0){
      ErrorToast("Email is required")
    }
    else if(!contactValue  ||contactValue.trim().length === 0){
      ErrorToast("Contact is required")
    }
    else {
      // Validation failed, show an error message for the contact field
      ErrorToast("Please fill in all required fields and provide a valid contact.");
    }
  
    // else if(!newData?.contact || newData?.contact.trim().length === 0){
    //   ErrorToast("contact is required")
    // }
  };
  const handleSubmit = async () => {
    let body = {
      firstName: newData?.firstName,
      // date: moment(newData?.date).format("MM-DD-YYYY"),
      // country: newData?.country,
    };

    await ApiPost("/users", body)
      .then((res) => {
        SuccessToast(res?.data?.message);
        setModal(false);
        fetchData(currentpage, pagesize, searching);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const imageChange = async (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("upload/image/profile_image", formData)
      .then((res) => {
        setNewData({ ...newData, thumbnailUrl: res?.data?.data?.image });
      })
      .catch((err) => console.log("res_blob", err));
  };
  const handlesearch = (e) => {
    setcurrentpage(1)
    setsearching(e.target.value);
    fetchData(1, pagesize, e.target.value);
  };
  //   const getCountry = async () => {
  //     let body = {
  //       page: 1,
  //       limit: 50,
  //       search: "",
  //     };
  //     await ApiPost("/get/country", body)
  //       .then((res) => {
  //         setCountryData(res?.data?.data?.response);
  //       })
  //       .catch((err) => ErrorToast(err?.message));
  //   };
  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    await ApiPost(`/getHistoryByUser/${UserIdQuery}`    , body)
      .then((res) => {
        console.log("/workout/getttttttt", res?.data?.response);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
    // getCountry();
  }, []);
  // useEffect(() => {
  //   fetchData(currentpage, pagesize, searching);
  // }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">History List</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              History
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              {/* <h3 class="card-label">Video Playlist</h3> */}
              {/* <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Email"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div> */}
            </div>

            <div class="card-toolbar">
              {/* <a
                class="btn btn-color font-weight-bolder btn-sm ms-4"
                // onClick={() => {
                //   setIsEdit(false);
                //   setModal(true);
                //   setNewData({
                //     name: "",
                //     date: moment(new Date()).format("YYYY-MM-DD"),
                //     country: "",
                //   });
                // }}
              >
                Add User
              </a> */}
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data || []}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataTable />}
            // filter={filterFactory()}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={modal} onHide={() => setModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>User Edit Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <Form.Group className="col-md-12">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                label="name"
                id="name"
                required
                name="name"
                onChange={handleChange}
                value={newData.name}
              />
              <span className="errorInput">
                {/* {newData.name?.length > 0 ? "" : errors["name"]} */}
              </span>
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                label="lastname"
                id="lastname"
                required
                name="lastname"
                onChange={handleChange}
                value={newData.lastname}
              />
              <span className="errorInput">
                {/* {newData.name?.length > 0 ? "" : errors["name"]} */}
              </span>
            </Form.Group>



            <Form.Group className="col-md-12">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                label="eamil"
                id="email"
                required
                name="email"
                onChange={handleChange}
                value={newData.email}
              />
              <span className="errorInput">
                {/* {newData.name?.length > 0 ? "" : errors["name"]} */}
              </span>
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Contact No.</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter contact no"
                label="contact"
                id="contact"
                required
                name="contact"
                onChange={handleChange}
                value={newData.contact}
              />
              <span className="errorInput">
                {/* {newData.name?.length > 0 ? "" : errors["name"]} */}
              </span>
            </Form.Group>
{/* <Form.Group className="col-md-12">
              <Form.Label>Event Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Enter Date"
                label            ="date"
                id="date"
                required
                name="date"
                role="button"
                onChange={handleChange}
                value={newData.date}
              />
              <span className="errorInput">
              </span>
            </Form.Group> */}

            {/* <Form.Group className="col-md-12">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                custom
                id="country"
                required
                className="form-control"
                name="country"
                onChange={handleChange}
                value={newData.country}
              >
                <option value="" disabled>
                  Select country
                </option>
                {countryData?.map((v, i) => {
                  return (
                    <>
                      <option value={v?.countryName}>{v?.countryName}</option>
                    </>
                  );
                })}
              </Form.Control>
            </Form.Group> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <button className="btn btn1 btn-color" onClick={onUpdate}>
              Update Changes
            </button>
          ) : (
            <button className="btn btn1 btn-color" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewModal}
        onHide={() => setViewModal(false)}
        centered
        className="modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>History Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Category Title</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.completion?.category_info?.title  ?? "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Category Name</div>
              <div className="col-md-7">{viewData?.completion?.category_info?.translatedName ?? "-"}</div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Task Title</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.completion?.task_info?.title ?? "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Task Name</div>
              <div className="col-md-7 text-capitalize">
              {viewData?.completion?.task_info?.translatedName ?? "-"}
              </div>
            </div>
            <h3>Form Info</h3>
            {
              viewData?.completion?.form_info?.fields_info?.map(data=>(
                <>
                <div className="row py-2">
              <div className="col-md-5 fw-bold">Field Id</div>
              <div className="col-md-7 text-capitalize">
              {data?.field_id ?? "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">User Input</div>
              <div className="col-md-7 text-capitalize">
              {data?.user_input ?? "-"}
              </div>
            </div>
                </>
              ))  
               
            }

<h3>Model Responses</h3>
            {
              viewData?.completion?.model_responses?.map(data=>(
                <>
                {
                  data?.role=="assistant"?
                  <div className="row py-2">
                  <div className="col-md-5 fw-bold">Content</div>
                  <div className="col-md-7 text-capitalize">
                  {data?.content ?? "-"}
                  </div>
                  <div className="col-md-5 fw-bold"></div>
                  <div className="col-md-7 text-capitalize">
                  {data?.translation ?? "-"}
                  </div>
                </div>:""
                }
              
                </>
              ))  
               
            }


<h3>Chat Info</h3>
            {
              viewData?.chat?.model_responses?.map(data=>(
                <>
                {
                  <div className="row py-2">
                  <div className="col-md-5 fw-bold">{data?.fromUser?"User":"GPT"}</div>
                  <div className="col-md-7 text-capitalize">
                  {data?.text ?? "-"}
                  </div>
                  </div>
                }
              
                </>
              ))  
               
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-color" onClick={() => setViewModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
