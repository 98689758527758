import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./Dashboard/DashboardPage";
import User from './pages/User/User'
import History from './pages/User/History'
import Categories from "./pages/User/Categories/Categories";
import Login from "./modules/Auth/pages/Login";
import Task from "./pages/Task/Task";
import { AuthPage } from "./modules/Auth/pages/AuthPage";
import AllNews from "./pages/News/Allnews";
import GlobalNews from "./pages/News/Globalnews";
// import  from './pages/User/Categories/Categories'
export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/auth/login" />
          // <ContentRoute exact path="/" to='/auth/login' />
        
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/user" component={User} />
        <ContentRoute path="/history" component={History} />
        <ContentRoute path="/categories" component={Categories} />
        <ContentRoute path="/tasks" component={Task} />
        <ContentRoute path="/all-news" component={AllNews} />
        <ContentRoute path="/global-news" component={GlobalNews} />

        {/* <ContentRoute path="/auth" component={AuthPage} /> */}
        {/* <ContentRoute path="/auth/login" component={Login} /> */}
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
