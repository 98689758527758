import React from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import deleteicon from "../../../../../src/media/delete_field.svg";

const DropdownOption = (props) => {
  return (
    <div
      className="col-md-12"
      key={props.input.id}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "3rem",
        position: "relative",
      }}
    >
      <Form.Group controlId="english" style={{ width: "40%" }}>
        <Form.Label>English value (Dropdown option):</Form.Label>
        <FormControl
          type="text"
          placeholder="Enter English text"
          style={{ background: "gray" }}
          value={props.input.value}
          onChange={(event) =>
            props.handleEnglishInputChange(
              props.parentID,
              event,
              "dropdownOption",
              props.input.id
            )
          }
        />
      </Form.Group>
      <Form.Group controlId="arabic" style={{ width: "40%" }}>
        <Form.Label>Arabic value (Dropdown option):</Form.Label>
        <FormControl
          type="text"
          style={{ background: "gray" }}
          placeholder="أدخل النص بالعربية"
          value={props.input.arabicText}
          onChange={(event) =>
            props.handleArabicInputChange(
              props.parentID,
              event,
              "dropdownOption",
              props.input.id
            )
          }
        />
      </Form.Group>
      <Form.Group controlId="optional" style={{ width: "30%", margin: 0 }}>
        <div
          // style={{ position: "absolute", left: "-5px", top: "0" }}
          onClick={() =>
            props.handleDeleteInput(
              props.parentID,
              "dropdownOption",
              props.input.id
            )
          }
        >
          {/* <img src={crossicon} alt='mySvgImage' /> */}
          <img
            src={deleteicon}
            style={{ width: "20px", height: "20px" }}
            alt="mySvgImage"
          />
        </div>
      </Form.Group>

      <hr />
    </div>
  );
};

export default DropdownOption;
