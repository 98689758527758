export const protocol = "https";
export const host = window.location.href.includes('admin-staging.roboti.app')?"dev-api.roboti.app":window.location.href.includes('admin.roboti.app')?"api.roboti.app":"dev-api.roboti.app";
export const port = "";
export const trailUrl = "";


// export const protocol = "http";
// export const host = "192.168.29.22";
// export const port = "8080";
// export const trailUrl = "";
