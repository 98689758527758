import React from "react";
import { Form, FormControl, Button } from "react-bootstrap";
import crossicon from "../../../../../src/media/delete_option.svg"

const MultipleInputField = (props) => {
  return (
    <div
      className="col-md-12"
      key={props.input.id}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "3rem",
        position: "relative",
      }}
    >
      <Form.Group controlId="english" style={{ width: "40%" }}>
      {
          props.sort? <Form.Label>ID: [{props.sort}] English:</Form.Label>:
          <Form.Label>ID: [{props.input.name}] English:</Form.Label>
        }
        <FormControl
          as="textarea"
          rows={3}
          placeholder="Enter English text"
          value={props.input.placeholder}
          onChange={(event) =>
            props.handleEnglishInputChange(
              props.input.id,
              event,
              "multipleInput"
            )
          }
        />
      </Form.Group> 
      <Form.Group controlId="arabic" style={{ width: "40%" }}>
        <Form.Label>Arabic:</Form.Label>
        <FormControl
          as="textarea"
          rows={3}
          placeholder="أدخل النص بالعربية"
          value={props.input.placeholder_ar}
          onChange={(event) =>
            props.handleArabicInputChange(
              props.input.id,
              event,
              "multipleInput"
            )
          }
        />
      </Form.Group>
      <Form.Group controlId="optional" style={{ width: "20%", margin: 0 }}>
        <Form.Check
          type="checkbox"
          label="Optional"
          checked={props.input.optional}
          onChange={(event) =>
            props.handleOptionalChange(props.input.id, event, "multipleInput")
          }
        />
      </Form.Group>
      <div
        // style={{ position: "absolute", left: "-5px", top: "0" }}
        onClick={() => props.handleDeleteInput(props.input.id, "multipleInput")}
      >
        <img src={crossicon} alt='mySvgImage' />
      </div>
      <hr />
    </div>
  );
};

export default MultipleInputField;
