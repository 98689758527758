import React, { useEffect, useState, useRef } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import NoDataTable from "../../../common/noDataTable";
import Pagination from "@material-ui/lab/Pagination";
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
} from "../../../helpers/API/ApiData";
// import { ErrorToast, SuccessToast } from "../../../../../helpers/Toast";
import { ErrorToast, SuccessToast } from "../../../helpers/Toast";
// import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import moment from "moment";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import Select from "react-select";
import { AiFillEye } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import FormAddSectionButton from "../../components/Task/FormAddSectionButton/FormAddSectionButton";
import SingleInputField from "../../components/Task/SingleInputField/SingleInputField";
import MultipleInputField from "../../components/Task/MultipleInputField/MultipleInputField";
import DropdownField from "../../components/Task/DropdownField/DropdownField";
import DropdownOption from "../../components/Task/DropdownField/DropdownOption";
import addicon from "../../../../src/media/add_option.svg";

export default function Task() {
  const date = new Date();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [searching, setsearching] = useState("");
  const [countryData, setCountryData] = useState([]);
  const [error, setError] = useState("");
  const [inputButtonClicked, setInputButtonClicked] = useState(false);
  const [toneButtonClicked, setToneButtonClicked] = useState(false);
  const [isTone, setIsTone] = useState(false);
  const [isLength, setIsLength] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isImageOptionalChecked, setIsImageOptionalChecked] = useState(false);
  const [isToneChecked, setIsToneChecked] = useState(false);
  const [isLengthChecked, setIsLengthChecked] = useState(false);
  const [isImageChecked, setIsImageChecked] = useState(false);
  const [sorting, setSorting] = useState(1);
  const [createBtnBool,setCreateBtnBool] = useState(false);
  const [updateBtnBool,setUpdateBtnBool] = useState(false);
  const [deleteBtnBool,setDeleteBtnBool] = useState(false);

  //Single Inputs Array
  const [singleInputs, setSingalInputs] = useState([]);

  //Multiple Inputs Array
  const [multipleInput, setMultipleinput] = useState([]);

  //Dropdown Inputs Array
  const [dropdownInputs, setDropdownInputs] = useState([]);

  const inputRef = useRef(null);
  const [newData, setNewData] = useState({
    image: "",
    categories: "",
    translaName: "",
    description: "",
    translaDescriptions: "",
    contact: "",
    getcategories: "",
    prompt: "",
    tone: false,
    date: moment(date).format("YYYY-MM-DD"),
    sorting: 1,
    // country: "",
  });
  //   const [viewData, setViewData] = useState({
  //     categories:'',
  //     description:''

  //   });

  const clearAllFormData = () => {
    setSingalInputs([]);
    setMultipleinput([]);
    setDropdownInputs([]);
    setIsTone(false);
    setIsLength(false);
    setIsImage(false);
    setIsImageOptionalChecked(false);
    setIsToneChecked(false);
    setIsLengthChecked(false);
    setIsImageChecked(false);
    setSorting(1);
  };

  const onCloseHandler = () => {
    setModal(false);
    clearAllFormData();
  };

  const incrementInput = () => {
    setSorting((prevState) => prevState + 1);
  };
  //Function generate Inputs for All Types
  const handleAddInput = (type, id) => {
    if (type === "singleInput") {
      if (singleInputs.length == 5) {
        return alert("limit reached");
      }

      incrementInput();
      const newIndex = singleInputs.length + 1;
      const newName = `SINGLE-INPUT-${newIndex}`;
      const newInput = {
        id: Date.now(),
        originalIndex: newIndex,
        name: newName,
        type: "input",
        placeholder: "",
        englishText: "",
        placeholder_ar: "",
        optional: false,
        sorting: sorting,
      };
      setSingalInputs([...singleInputs, newInput]);
    } else if (type === "multipleInput") {
      if (multipleInput.length == 5) {
        return alert("limit reached");
      }

      incrementInput();
      setMultipleinput([
        ...multipleInput,
        {
          id: Date.now(),
          name: `DYNAMIC-INPUT-${multipleInput.length + 1}`,
          type: "textarea",
          placeholder: "",
          englishText: "",
          placeholder_ar: "",
          optional: false,
          sorting: sorting,
        },
      ]);
    } else if (type === "dropdownInput") {
      if (dropdownInputs.length == 5) {
        return alert("limit reached");
      }

      incrementInput();
      setDropdownInputs([
        ...dropdownInputs,
        {
          id: Date.now(),
          name: `DROPDOWN-INPUT-${dropdownInputs.length + 1}`,
          type: "dropdown",
          placeholder: "",
          label: "",
          placeholder_ar: "",
          optional: false,
          sorting: sorting,
          options: [{ id: Date.now(), value: "", text: "", arabicText: "" }],
        },
      ]);
    } else if (type === "dropdownInputOption") {
      setDropdownInputs(
        dropdownInputs.map((input, index) => {
          // Check if the current input matches the ID
          // console.log({ id });
          if (index === id) {
            return {
              ...input,
              options: [
                ...input.options,
                { id: Date.now(), value: "", text: "", arabicText: "" },
              ],
            };
          } else {
            // If not, return the input as is
            return input;
          }
        })
      );
    }
  };

  // console.log("multiple inputs: ", { multipleInput });

  // Function responsible to change input field of all types inputs (English)
  const handleEnglishInputChange = (id, event, type, optionId) => {
    let updatedInputs = [];
    if (type === "singleInput") {
      updatedInputs = singleInputs.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            placeholder: `${event.target.value}`,
            englishText: event.target.value,
          };
        }
        return input;
      });
      setSingalInputs(updatedInputs);
    } else if (type === "multipleInput") {
      updatedInputs = multipleInput.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            placeholder: `${event.target.value}`,
            englishText: event.target.value,
          };
        }
        return input;
      });
      setMultipleinput(updatedInputs);
    } else if (type === "dropdownInput") {
      updatedInputs = dropdownInputs.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder: event.target.value };
        }
        return input;
      });
      setDropdownInputs(updatedInputs);
    } else if (type === "dropdownOption") {
      // Update options within a dropdown input
      updatedInputs = dropdownInputs.map((input) => {
        if (input.id === id) {
          const updatedOptions = input.options.map((option) => {
            if (option.id === optionId) {
              return {
                ...option,
                value: event.target.value,
                text: event.target.value,
              };
            }
            return option;
          });
          return { ...input, options: updatedOptions };
        }
        return input;
      });
      setDropdownInputs(updatedInputs);
    }
  };
  console.log("multiple inputs: ", { multipleInput });
  console.log("dropdown inputs: ", { dropdownInputs });
  console.log("single inputs: ", { singleInputs });

  // Function responsible to change input field of all types inputs (Arabic)

  const handleArabicInputChange = (id, event, type, optionId) => {
    let updatedInputs = [];
    if (type === "singleInput") {
      updatedInputs = singleInputs.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder_ar: event.target.value };
        }
        return input;
      });
      setSingalInputs(updatedInputs);
    } else if (type === "multipleInput") {
      updatedInputs = multipleInput.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder_ar: event.target.value };
        }
        return input;
      });
      setMultipleinput(updatedInputs);
    } else if (type === "dropdownInput") {
      updatedInputs = dropdownInputs.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder_ar: event.target.value };
        }
        return input;
      });
      setDropdownInputs(updatedInputs);
    } else if (type === "dropdownOption") {
      // Update options within a dropdown input
      updatedInputs = dropdownInputs.map((input) => {
        if (input.id === id) {
          const updatedOptions = input.options.map((option) => {
            if (option.id === optionId) {
              return { ...option, arabicText: event.target.value };
            }
            return option;
          });
          return { ...input, options: updatedOptions };
        }
        return input;
      });
      setDropdownInputs(updatedInputs);
    }
  };

  // Function responsible to manage optional flag  inputs

  const handleOptionalChange = (id, event, type) => {
    let updatedInputs = [];
    if (type === "singleInput") {
      updatedInputs = singleInputs.map((input) => {
        if (input.id === id) {
          return { ...input, optional: event.target.checked };
        }
        return input;
      });
      console.log(updatedInputs);
      setSingalInputs(updatedInputs);
    } else if (type === "multipleInput") {
      updatedInputs = multipleInput.map((input) => {
        if (input.id === id) {
          return { ...input, optional: event.target.checked };
        }
        return input;
      });
      setMultipleinput(updatedInputs);
    } else if (type === "dropdownInput") {
      updatedInputs = dropdownInputs.map((input) => {
        if (input.id === id) {
          return { ...input, optional: event.target.checked };
        }
        return input;
      });
      setDropdownInputs(updatedInputs);
    }
  };

  // Function for delete field

  const handleDeleteInput = (id, type, optionId) => {
    console.log("dlelelelelleleleelelkel")
    let updatedInputs = [];
    if (type === "singleInput") {
      updatedInputs = singleInputs.filter((input) => input.id !== id);
      setSingalInputs(updatedInputs);
    } else if (type === "multipleInput") {
      updatedInputs = multipleInput.filter((input) => input.id !== id);
      setMultipleinput(updatedInputs);
    } else if (type === "dropdownInput") {
      updatedInputs = dropdownInputs.filter((input) => input.id !== id);
      setDropdownInputs(updatedInputs);
    } else if (type === "dropdownOption") {
      updatedInputs = dropdownInputs.map((input) => {
        if (input.id === id) {
          // Filter out the option with the specified ID
          const updatedOptions = input.options.filter(
            (option) => option.id !== optionId
          );
          return { ...input, options: updatedOptions };
        }
        return input;
      });
      setDropdownInputs(updatedInputs);
    }
  };

  //new global array
  const [arr, setArr] = useState([]);

  //get input with defined type

  //new add fun
  const handleAdd = (type, id) => {
    let inputType = '';
    let inputPrefix = '';
    switch (type) {
      case 'singleInput':
        inputType = 'input';
        inputPrefix = 'SINGLE-INPUT';
        break;
      case 'multipleInput':
        inputType = 'textarea';
        inputPrefix = 'MULTIPLE-INPUT';
        break;
      case 'dropdownInput':
        inputType = 'dropdown';
        inputPrefix = 'DROPDOWN-INPUT';
        break;
        case 'dropdownInputOption':
          inputType = 'dropdownInputOption';
          inputPrefix = 'DROPDOWN-INPUT';
          break;
      default:
        break;
    }
  
    const inputCount = arr.filter(item => item.type === inputType).length;
    if (inputCount >= 5 && inputType!="dropdownInputOption") {
      alert('Maximum limit reached');
      return;
    }
  
    incrementInput();
    if(inputType!="dropdownInputOption"){
    setArr(prevArr => [
      ...prevArr,
      {
        id: Date.now(),
        name: `${inputPrefix}-${inputCount + 1}`,
        type: inputType,
        placeholder: '',
        englishText: '',
        placeholder_ar: '',
        optional: false,
        sorting: sorting,
        options: inputType === 'dropdown' ? [{ id: Date.now(), value: '', text: '', arabicText: '' }] : [],
      },
    ]);
  }
  else{
    setArr(
      arr.map((input, index) => {
        // Check if the current input matches the ID
        console.log({ id });
        if (index === id) {
          return {
            ...input,
            options: [
              ...input.options,
              { id: Date.now(), value: "", text: "", arabicText: "" },
            ],
          };
        } else {
          // If not, return the input as is
          return input;
        }
      })
    );
  }
  };
  

  // console.log("arr values - handle add", arr);

  const handleEnglishInputChanges = (id, event, type, optionId) => {
    let updatedInputs = [];
    if (type === "singleInput") {
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            placeholder: `${event.target.value}`,
            englishText: event.target.value,
          };
        }
        return input;
      });
      setArr(updatedInputs);
    } else if (type === "multipleInput") {
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          return {
            ...input,
            placeholder: `${event.target.value}`,
            englishText: event.target.value,
          };
        }
        return input;
      });
      setArr(updatedInputs);
    } else if (type === "dropdownInput") {
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder: event.target.value };
        }
        return input;
      });
      setArr(updatedInputs);
    } else if (type === "dropdownOption") {
      // Update options within a dropdown input
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          const updatedOptions = input.options.map((option) => {
            if (option.id === optionId) {
              return {
                ...option,
                value: event.target.value,
                text: event.target.value,
              };
            }
            return option;
          });
          return { ...input, options: updatedOptions };
        }
        return input;
      });
      setArr(updatedInputs);
    }
  };

  const handleArabicInputChanges = (id, event, type, optionId) => {
    let updatedInputs = [];
    if (type === "singleInput") {
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder_ar: event.target.value };
        }
        return input;
      });
      setArr(updatedInputs);
    } else if (type === "multipleInput") {
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder_ar: event.target.value };
        }
        return input;
      });
      setArr(updatedInputs);
    } else if (type === "dropdownInput") {
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          return { ...input, placeholder_ar: event.target.value };
        }
        return input;
      });
      setArr(updatedInputs);
    } else if (type === "dropdownOption") {
      // Update options within a dropdown input
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          const updatedOptions = input.options.map((option) => {
            if (option.id === optionId) {
              return { ...option, arabicText: event.target.value };
            }
            return option;
          });
          return { ...input, options: updatedOptions };
        }
        return input;
      });
      setArr(updatedInputs);
    }
  };

  const handleOptionalChanges = (id, event, type) => {
    let updatedInputs = [];
    if (
      type === "singleInput" ||
      type === "multipleInput" ||
      type === "dropdownInput"
    ) {
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          return { ...input, optional: event.target.checked };
        }
        return input;
      });
    }
    setArr(updatedInputs);
  };

  // const handleOptionalChanges = (id, event, type) => {
  //   let updatedInputs = [];
  //   if (type === "singleInput") {
  //     updatedInputs = arr.map((input) => {
  //       if (input.id === id) {
  //         return { ...input, optional: event.target.checked };
  //       }
  //       return input;
  //     });
  //     console.log(updatedInputs);
  //     setArr(updatedInputs);
  //   } else if (type === "multipleInput") {
  //     updatedInputs = arr.map((input) => {
  //       if (input.id === id) {
  //         return { ...input, optional: event.target.checked };
  //       }
  //       return input;
  //     });
  //     setArr(updatedInputs);
  //   } else if (type === "dropdownInput") {
  //     updatedInputs = arr.map((input) => {
  //       if (input.id === id) {
  //         return { ...input, optional: event.target.checked };
  //       }
  //       return input;
  //     });
  //     setArr(updatedInputs);
  //   }else if (type === "dropdownOption") {
  //     updatedInputs = arr.map((input) => {
  //       if (input.id === id) {
  //         return { ...input, optional: event.target.checked };
  //       }
  //       return input;
  //     });
  //     setArr(updatedInputs);
  //   }

  //   // dropdownOption
  // };

  const handleDeleteInputs = (id, type, optionId) => {
    console.log("jghjhgjhgjhgjhjghjhgjhg")
    let updatedInputs = [];
    if (type === "singleInput") {
      // Filter out the input with the specified ID
      updatedInputs = arr.filter((input) => input.id !== id);
  
      // Update names for the remaining items to maintain sequence
      let singleInputCount = 1;
      updatedInputs = updatedInputs.map((input) => {
        if (input.name.toLowerCase().includes("single-input")) {
          return { ...input, name: `SINGLE-INPUT-${singleInputCount++}` };
        }
        return input;
      });
  
      setArr(updatedInputs);
      console.log(updatedInputs, "Updated Inputs");
    }else if (type === "multipleInput") {
      // Filter out the input with the specified ID
      updatedInputs = arr.filter((input) => input.id !== id);
  
      // Update names for the remaining items to maintain sequence
      let multipleInputCount = 1;
      updatedInputs = updatedInputs.map((input) => {
        if (input.name.toUpperCase().includes("MULTIPLE-INPUT")) {
          return { ...input, name: `MULTIPLE-INPUT-${multipleInputCount++}` };
        }
        return input;
      });
  
      setArr(updatedInputs);
    } else if (type === "dropdownInput") {
      // Filter out the input with the specified ID
      updatedInputs = arr.filter((input) => input.id !== id);
  
      // Update names for the remaining items to maintain sequence
      let dropdownInputCount = 1;
      updatedInputs = updatedInputs.map((input) => {
        if (input.name.toUpperCase().includes("DROPDOWN-INPUT")) {
          return { ...input, name: `DROPDOWN-INPUT-${dropdownInputCount++}` };
        }
        return input;
      });
  
      setArr(updatedInputs);
    } else if (type === "dropdownOption") {
      // Update options within the specified input
      updatedInputs = arr.map((input) => {
        if (input.id === id) {
          // Filter out the option with the specified ID
          const updatedOptions = input.options.filter(
            (option) => option.id !== optionId
          );
  
          // Update IDs for the remaining options
          const updatedOptionsWithNewIds = updatedOptions.map((option, index) => ({
            ...option,
            id: `option-${index + 1}`,
          }));
  
          return { ...input, options: updatedOptionsWithNewIds };
        }
        return input;
      });
  
      setArr(updatedInputs);
    }
  };

  // console.log({ arr });
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      // sort: true,
      formatter: (cell, row, i) => {
        return (
          <div className="d-flex align-items-center">
            {(currentpage - 1) * pagesize + i + 1 || "-"}
          </div>
        );
      },
    },
    // {
    //   dataField: "image",
    //   text: "Image",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return (
    //       <div className="cursor-pointer"
    //     //    onClick={() => viewBtnClick(row)}
    //        >
    //         {/* <img src={row?.image} alt="loading.." srcset="" /> */}
    //         <img
    //           src={
    //             row?.image
    //               ? `${row?.image}`
    //               : "https://static.vecteezy.com/system/resources/thumbnails/014/066/812/small/discount-ribbon-banner-icon-for-graphic-design-logo-website-social-media-mobile-app-ui-illustration-vector.jpg"
    //           }
    //           alt=""
    //           width={50}
    //           height={50}
    //           className="rounded object-cover"
    //         />

    //        </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "Categories",
    //   text: "Categories",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <div>{cell || "-"}</div>;
    //   },
    // },
    {
      dataField: "Taskname",
      text: "Task Name",
      sort: true,
      formatter: (cell, row) => {
        // console.log('row1', row)
        return <div>{row?.name ? row?.name : "-"}</div>;
      },
    },
    {
      dataField: "category_data",
      text: "category Name",
      sort: true,
      formatter: (cell, row) => {
        // console.log("row1", row);
        return (
          <div>
            {row?.category_data[0]?.name ? row?.category_data[0]?.name : "-"}
          </div>
        );
      },
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
      formatter: (cell, row) => {
        const descriptionStyle = {
          width: "200px", // Replace with your desired width
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
        };
        return (
          <div onClick={() => viewBtnClick(row)} style={descriptionStyle}>
            {row?.descripiton ? row?.descripiton : "-"}
          </div>
        );
      },
    },
    {
      dataField: "prompt",
      text: "Prompt",
      sort: true,
      formatter: (cell, row) => {
        const descriptionStyle = {
          width: "200px", // Replace with your desired width
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
        };
        return (
          <div onClick={() => viewBtnClick(row)} style={descriptionStyle}>
            {row?.prompt ? row?.prompt : "-"}
          </div>
        );
      },
    },
    // {
    //   dataField: "gender",
    //   text: "Gender",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return <div>{row?.gender === 0 ? "Male" : "Female"}</div>;
    //   },
    // },
    // {
    //   dataField: "dob",
    //   text: "DOB",
    //   sort: true,
    //   formatter: (cell, row) => {
    //     return (
    //       <div>{cell ? moment(cell).format("DD-MM-YYYY") : "-" || "-"}</div>
    //     );
    //   },
    // },
    {
      dataField: "createdAt",
      text: "Created Date",
      sort: true,
      formatter: (cell, row) => {
        return <div>{moment(cell).format("DD-MM-YYYY") || "-"}</div>;
      },
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {/* <a
              title="View User"
              className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
              onClick={() => viewBtnClick(row)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary eye-icon">
                <AiFillEye className="" />
              </span>
            </a> */}
            {/* <td className="d-flex"> */}
            <div className="d-flex">
              <a
                title="Edit customer"
                className="btn btn-icon btn-light btn-hover-primary btn-sm me-3"
                onClick={() => editBtnClick(row)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>

              <a
                title="Delete customer"
                className="btn btn-icon btn-light btn-hover-danger btn-sm"
                onClick={() => {
                  Swal.fire({
                    text: `Are you sure you want to Delete ?`,
                    icon: "warning",
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: `Yes, Delete`,
                    confirmButtonColor: "#D72852",
                    cancelButtonColor: "black",
                    cancelButtonText: "No, Cancel",
                  }).then((res) => {
                    if (res.isConfirmed) {
                      if(!deleteBtnBool){
                      onDelete(row?._id);
                      }
                    }
                  });
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </div>
            {/* </td> */}
          </>
        );
      },
    },
  ];

  const handleChange = (e) => {
    const { value, name, id } = e.target;
    const trimmedValue = value.trimStart();
    // console.log("name: ", name, "value: ", value)

    if (name === "promptType") {
      let updatedPrompt = newData.prompt;
      let insertionText = "";

      // Determine the insertion text based on the value
      if (value === "input") {
        insertionText = `[INPUT${id + 1}]`;
        setInputButtonClicked(true);
        // setToneButtonClicked(false);
      } else if (value === "tone") {
        insertionText = " [TONE]";
        setToneButtonClicked(true);
        // setInputButtonClicked(false);
      } else if (value === "textarea") {
        insertionText = `[DYNAMIC${id + 1}]`;
        setInputButtonClicked(true);
        // setToneButtonClicked(false);
      } else if (value === "dropdown") {
        insertionText = `[DROPDOWN${id + 1}]`;
        setInputButtonClicked(true);
        // setToneButtonClicked(false);
      }

      // Insert text at the cursor position
      const cursorPosition = inputRef.current.selectionStart;
      updatedPrompt =
        updatedPrompt.substring(0, cursorPosition) +
        insertionText +
        updatedPrompt.substring(cursorPosition);

      setNewData((prevData) => ({ ...prevData, prompt: updatedPrompt }));
    } else {
      setNewData((prevData) => ({ ...prevData, [name]: trimmedValue }));
    }
  };

  // const handleChange = (e) => {
  //   const { value, name, id } = e.target;
  //   const trimmedValue = value.trimStart();

  //   if (name === "promptType") {
  //     let updatedPrompt = newData.prompt;

  //     if (value === "input") {
  //       updatedPrompt += `[INPUT${id + 1}]`;
  //       setInputButtonClicked(true);
  //       // setToneButtonClicked(false);
  //     } else if (value === "tone") {
  //       updatedPrompt += " [TONE]";
  //       setToneButtonClicked(true);
  //       // setInputButtonClicked(false);
  //     }else if (value === "textarea") {
  //       updatedPrompt += `[DYNAMIC${id + 1}]`;
  //       setInputButtonClicked(true);
  //       // setToneButtonClicked(false);
  //     } else if (value === "dropdown") {
  //       updatedPrompt += `[DROPDOWN${id + 1}]`;
  //       setInputButtonClicked(true);
  //       // setToneButtonClicked(false);
  //     }
  //     setNewData((prevData) => ({ ...prevData, prompt: updatedPrompt }));
  //   } else {
  //     setNewData((prevData) => ({ ...prevData, [name]: trimmedValue }));
  //   }
  // };

  // console.log("newData before edit", newData);

  const editBtnClick = async (row) => {
    setArr([])
    await ApiGet(`/task/${row?._id}`)
      .then((res) => {
        // Sort the field array based on the sorting value
        const sortedFields = res.data.data[0].field.sort((a, b) => parseInt(a.sorting) - parseInt(b.sorting));
        console.log("getApi", sortedFields);
        setSorting(parseInt(res.data.data[0].field[res.data.data[0].field.length-1].sorting)+1)
  
        // Iterate over the sorted fields
        sortedFields.forEach((input) => {
          console.log(input,"okodkkdofkodkfodkf");
          if (input.type === "input") {
            setArr((prevArr) => [
              ...prevArr,
              {
                id: Date.now(),
                name: input.name,
                type: input.type,
                placeholder: input.placeholder,
                englishText: input.placeholder,
                placeholder_ar: input.placeholder_ar,
                optional: input.optional,
                sorting: input.sorting,
              },
            ]);
          } else if (input.type === "textarea") {
            setArr((prevArr) => [
              ...prevArr,
              {
                id: Date.now(),
                name: input.name,
                type: input.type,
                placeholder: input.placeholder,
                englishText: input.placeholder,
                placeholder_ar: input.placeholder_ar,
                optional: input.optional,
                sorting: input.sorting,
              },
            ]);
          } else if (input.type === "dropdown") {
            setArr((prevArr) => [
              ...prevArr,
              {
                id: Date.now(),
                name: input.name,
                type: input.type,
                englishText: input.placeholder,
                placeholder: input.placeholder,
                label: input.placeholder,
                placeholder_ar: input.placeholder_ar,
                optional: input.optional,
                sorting: input.sorting,
                options: input.option,
              },
            ]);
          }
        });
  
        setIsTone(res.data.data[0].tone || false);
        setIsToneChecked(res.data.data[0].tone || false);
        setIsLength(res.data.data[0].length || false);
        setIsLengthChecked(res.data.data[0].length || false);
        setIsImage(res.data.data[0].image || false);
        setIsImageChecked(res.data.data[0].image || false);
        setIsImageOptionalChecked(res.data.data[0].imageOptional || false);
      })
      .catch((err) => ErrorToast(err?.message));
  
    setIsEdit(true);
    setModal(true);
    setInputButtonClicked(false);
    setToneButtonClicked(false);
  
    setNewData({
      id: row?._id,
      image: row?.image,
      categories: row?.name,
      translaName: row?.translaName,
      description: row?.descripiton,
      translaDescriptions: row?.translaDescriptions,
      contact: row?.phoneNumber,
      getcategories: row?.categoryId,
      prompt: row?.prompt,
      date: moment(row?.createdAt).format("YYYY-MM-DD"),
    });
  };
  

  const viewBtnClick = (row) => {
    // setIsEdit(true);
    // console.log("rowedit", row);
    // console.log('newDataedit', viewData)
    setViewModal(true);
    // setViewData(row);
    // setViewData({
    //     categories: row?.name,
    //     description: row?.description,
    //   });

    setNewData({
      id: row?._id,
      categories: row?.name,
      translaName: row?.translaName,
      description: row?.descripiton,
      translaDescriptions: row?.translaDescriptions,
      getcategories: row?.category_data[0]?.name,
      prompt: row?.prompt,
      // date: moment(row?.date).format("YYYY-MM-DD"),
      // country: row?.country,
    });
  };

  const onDelete = async (Id) => {
    setDeleteBtnBool(true)
    await ApiDelete(`/task/${Id}`)
      .then(() => {
        Swal.fire({
          text: "You have Deleted Successfully!!!",
          icon: "success",
          confirmButtonText: "Ok, Got it!",
          confirmButtonColor: "#338DE6",
        });
        fetchData(currentpage, pagesize, searching);
        setDeleteBtnBool(false)
      })
      .catch((err) => {
        ErrorToast(err?.message)
        setDeleteBtnBool(false)
      });
  };

  const onUpdate = async () => {
    setUpdateBtnBool(true)
    let body = {
      id: newData?.id,
      //   image: newData?.image,
      name: newData?.categories,
      translaName: newData?.translaName,
      descripiton: newData?.description,
      translaDescriptions: newData?.translaDescriptions,
      categoryId: newData?.getcategories,
      prompt: newData?.prompt,
      tone: isToneChecked,
      length: isLengthChecked,
      image: isImageChecked,
      imageOptional: isImageOptionalChecked,
      // phoneNumber:newData?.contact,
      //   createdAt: moment(newData?.date).format("MM-DD-YYYY"),
      // country: newData?.country,
    };
    // console.log("body", body);

    if (
      newData?.categories.trim().length > 0 &&
      newData?.description.trim().length > 0 &&
      newData?.prompt &&
      newData?.getcategories &&
      newData?.getcategories.length > 0 &&
      newData?.getcategories !== ""
    ) {
      const Input = [];
    
    // Add elements from singleInputs
    singleInputs.forEach((val) => Input.push(val));

    // Add elements from multipleInput
    multipleInput.forEach((val) => Input.push(val));

    // Add elements from dropdownInputs
    dropdownInputs.forEach((val) => Input.push(val));
    
    // Add elements from arr
    arr.forEach((val) => Input.push(val));

    body.form = Input;

    console.log("submit body", body);
      // return

      await ApiPut("/task", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          clearAllFormData();
          fetchData(currentpage, pagesize, searching);
          setSingalInputs([])
          setMultipleinput([])
          setDropdownInputs([])
          setArr([])
          setUpdateBtnBool(false)
          // window.location.reload(false)
        })
        .catch((err) => {
          ErrorToast(err?.message)
          setUpdateBtnBool(false)
        });
    } else if (
      !newData?.getcategories ||
      newData?.getcategories.length === 0 ||
      newData?.getcategories === ""
    ) {
      ErrorToast("Category is Required!");
      setUpdateBtnBool(false)
    } else if (
      !newData?.categories ||
      newData?.categories.trim().length === 0
    ) {
      ErrorToast("Task is Required!");
      setUpdateBtnBool(false)
    } else if (
      !newData?.description ||
      newData?.description.trim().length === 0
    ) {
      ErrorToast("Description is Required!");
      setUpdateBtnBool(false)
    } else if (!newData?.prompt || newData?.prompt.length === 0) {
      ErrorToast("prompt is Required!");
      setUpdateBtnBool(false)
    }
  };
  const handleSubmit = async () => {
    // let combinedData = {singleInputs, multipleInput, dropdownInputs};
    // console.log("combined data: ", combinedData);
    setCreateBtnBool(true)
    let body = {
      // firstName: newData?.firstName,
      // image: newData?.image,
      name: newData?.categories,
      translaName: newData?.translaName,
      descripiton: newData?.description,
      translaDescriptions: newData?.translaDescriptions,
      categoryId: newData?.getcategories,
      prompt: newData?.prompt,
      tone: isToneChecked,
      length: isLengthChecked,
      image: isImageChecked,
      imageOptional: isImageOptionalChecked,
      
    };
    if (
      newData?.categories.trim().length > 0 &&
      newData?.description.trim().length > 0 &&
      newData?.prompt &&
      newData?.getcategories &&
      newData?.getcategories.length > 0 &&
      newData?.getcategories !== ""
    ) {
      const Input = [];

      // singleInputs.map((val) => Input.push(val));

      // multipleInput.map((val) => Input.push(val));

      // dropdownInputs.map((val) => Input.push(val));
      arr.map((val) => Input.push(val));

      body.form = Input;


      console.log("submitbody", body);
      // return
      debugger;

      await ApiPost("/task", body)
        .then((res) => {
          SuccessToast(res?.data?.message);
          setModal(false);
          clearAllFormData();
          fetchData(currentpage, pagesize, searching);
          setSingalInputs([])
          setMultipleinput([])
          setDropdownInputs([])
          setArr([])
          // window.location.reload(false)
          setCreateBtnBool(false)
        })
        .catch((err) => {
          ErrorToast(err?.message)
          setCreateBtnBool(false)
        });
    } else if (
      !newData?.getcategories ||
      newData?.getcategories.length === 0 ||
      newData?.getcategories === ""
    ) {
      ErrorToast("Category is Required!");
      setCreateBtnBool(false)
    } else if (
      !newData?.categories ||
      newData?.categories.trim().length === 0
    ) {
      ErrorToast("Task is Required!");
      setCreateBtnBool(false)
    } else if (
      !newData?.description ||
      newData?.description.trim().length === 0
    ) {
      ErrorToast("Description is Required!");
      setCreateBtnBool(false)
    } else if (!newData?.prompt || newData?.prompt.length === 0) {
      ErrorToast("prompt is Required!");
      setCreateBtnBool(false)
    }
  };
  const handleonchnagespagination = (e) => {
    setpagesize(e.target.value);
    setcurrentpage(1);
    fetchData(1, parseInt(e.target.value), searching);
  };
  const onPaginationChange = (e, i) => {
    setcurrentpage(i);
    fetchData(i, pagesize, searching);
  };
  const imageChange = async (e) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    let formData = new FormData();
    formData.append("image", file);
    await ApiUpload("admin/image", formData)
      .then((res) => {
        // console.log("res", res);
        setNewData({ ...newData, image: res?.data?.data });
      })
      .catch((err) => console.log("res_blob", err));
  };
  const handlesearch = (e) => {
    setcurrentpage(1);
    setsearching(e.target.value);
    fetchData(1, pagesize, e.target.value);
  };
  const getCountry = async () => {
    let body = {
      page: 1,
      limit: 50,
      search: "",
    };
    await ApiPost("/getcategory", body)
      .then((res) => {
        setCountryData(res?.data?.data?.response);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  const fetchData = async (page, limit, search) => {
    let body = { page, limit, search };
    await ApiPost("/getTask", body)
      .then((res) => {
        // console.log("/workout/get", res?.data?.data);
        setData(res?.data?.data?.response);
        settotalpage(res?.data?.data?.state?.page_limit);
      })
      .catch((err) => ErrorToast(err?.message));
  };
  useEffect(() => {
    fetchData(currentpage, pagesize, searching);
    getCountry();
  }, []);
  // useEffect(() => {
  //   fetchData(currentpage, pagesize, searching);
  // }, []);
  return (
    <>
      <div className="d-flex justify-content-between mb-4">
        <div className="title">
          <div className="fs-20px fw-bolder">Tasks List</div>
          <div>
            <span
              role="button"
              onClick={() => history.push("/dashboard")}
              className="text-hover-info text-muted"
            >
              Home
            </span>{" "}
            -{" "}
            <span className="text-muted" role="button">
              {" "}
              Tasks
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-column-fluid h-100" id="kt_content">
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0 w-100">
            <div class="card-title ">
              {/* <h3 class="card-label">Video Playlist</h3> */}
              <div class="input-icon">
                <input
                  type="text"
                  class="form-control bg-light"
                  name="searchText"
                  placeholder="Search by Tasks"
                  value={searching}
                  onChange={(e) => handlesearch(e)}
                  id="kt_datatable_search_query"
                />
                <span>
                  <i class="flaticon2-search-1 text-muted"></i>
                </span>
              </div>
            </div>

            <div class="card-toolbar">
              <a
                class="btn btn1 btn-color  font-weight-bolder btn-sm ms-4"
                onClick={() => {
                  setArr([])
                  setIsEdit(false);
                  setModal(true);
                  setInputButtonClicked(false);
                  setToneButtonClicked(false);

                  setNewData({
                    image: "",
                    categories: "",
                    description: "",
                    contact: "",
                    getcategories: "",
                    date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
              >
                Add Tasks
              </a>
            </div>
          </div>
          <div className="card-body mb-5">
            <BootstrapTable
              wrapperClasses="table-responsive"
              headerWrapperClasses="border-0"
              bordered={false}
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              keyField="_id"
              // selectRow={selectRow}
              data={data || []}
              columns={columns}
              // pagination={paginationFactory(options)}
              // defaultSorted={defaultSorted}
              noDataIndication={() => <NoDataTable />}
              // filter={filterFactory()}
            />
            <div class="d-flex justify-content-between  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={onPaginationChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div class="my-2">
                <div class="d-flex align-items-center pagination-drpdown">
                  <select
                    class="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={modal} onHide={() => onCloseHandler()} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {!isEdit ? " Add Tasks" : "Tasks Edit Details"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <Form.Group className="col-md-12"> */}
            {/* <Form.Label>image</Form.Label>
              <Form.Control
                type="image"
                placeholder="Select image"
                label="image"
                id="image"
                required
                name="image"
                onChange={handleChange}
                value={newData.image}
              />
              <span className="errorInput">
              </span>
            </Form.Group> */}
            {/* <Form.Label>Image</Form.Label> */}
            {/* <input
                type="file"
                className="form-control"
                name="image"
                id="image"
                onChange={imageChange}
              /> */}
            {/* <span className="errorInput"></span> */}
            {/* </Form.Group> */}
            {/* {newData?.image && (
              <div className="position-relative w-auto">
                <img
                  src={ newData?.image}
                  alt="sd"
                  style={{ width: "100px", height: "100px" }}
                  className="rounded-3"
                />
                <span
                  className="imageRemovoIconSpan"
                  onClick={() => {
                    setNewData({ ...newData, image: "" });
                    document.getElementById("image").value = null;
                  }}
                >
                  <MdCancel color="red" fontSize={20} />
                </span>
              </div>
            )} */}

            <Form.Group className="col-md-12">
              <Form.Label>Categories</Form.Label>
              <Form.Control
                as="select"
                custom
                id="country"
                required
                className="form-control"
                name="getcategories"
                onChange={handleChange}
                value={newData.getcategories}
              >
                <option value="">Select Category</option>
                {countryData?.map((v, i) => {
                  return (
                    <>
                      <option value={v?._id}>{v?.name}</option>
                    </>
                  );
                })}
              </Form.Control>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Tasks</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Tasks"
                label="categories"
                id="categories"
                required
                name="categories"
                onChange={handleChange}
                value={newData.categories}
              />
              <span className="errorInput">
                {/* {newData.categories?.length > 0 ? "" : errors["categories"]} */}
                {/* {newData.categories?.length > 0 ? "" : ErrorToast("Enter categories")} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Tasks (AR)</Form.Label>
              <Form.Control
                type="text"
                placeholder="أدخل المهام"
                label="translaName"
                id="translaName"
                name="translaName"
                onChange={handleChange}
                style={{ textAlign: 'right' }}
                value={newData.translaName}
              />
              <span className="errorInput">
                
              </span>
            </Form.Group>

            <Form.Group className="col-md-12">
              <Form.Label>Description</Form.Label>
              <Form.Control
                // type="text"
                // placeholder="Enter Description"
                // label="description"
                // id="description"
                // required
                // name="description"
                // onChange={handleChange}
                // value={newData.description}
                as="textarea"
                rows={3}
                placeholder="Enter Description"
                label="description"
                id="description"
                required
                name="description"
                onChange={handleChange}
                value={newData.description}
                style={{ whiteSpace: "pre-wrap" }}
              />
              <span className="errorInput">
                {/* {newData.description?.length > 0 ? "" : errors["name"]} */}
              </span>
            </Form.Group>
            <Form.Group className="col-md-12">
              <Form.Label>Description (AR)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="أدخل الوصف"
                label="translaDescriptions"
                id="translaDescriptions"
                name="translaDescriptions"
                onChange={handleChange}
                value={newData.translaDescriptions}
                style={{ whiteSpace: "pre-wrap", textAlign: 'right' }}
              />
              <span className="errorInput">
                
              </span>
            </Form.Group>
                {console.log(singleInputs,"hello single input")}
                {singleInputs.length > 0 &&
  singleInputs
    .slice()
    .sort((a, b) => {
      const indexA = parseInt(a.name.split('-')[2]);
      const indexB = parseInt(b.name.split('-')[2]);
      return indexA - indexB;
    })
    .map((input, index) => (
      <>
        <div
          onClick={() => handleDeleteInput(input.id, "singleInput")}
        >
          X
        </div>
        <SingleInputField
          input={input}
          handleEnglishInputChange={handleEnglishInputChange}
          handleArabicInputChange={handleArabicInputChange}
          handleOptionalChange={handleOptionalChange}
          handleDeleteInput={handleDeleteInput}
          handleSetSingleInput={setSingalInputs}
          singleInputs={singleInputs}
          index={index}
          sort={input.name}
        />
      </>
    ))}

            {multipleInput.length > 0 &&
              multipleInput.slice()
              .sort((a, b) => {
                const indexA = parseInt(a.name.split('-')[2]);
                const indexB = parseInt(b.name.split('-')[2]);
                return indexA - indexB;
              })
              .map((input, index) => (
                <>
                  <MultipleInputField
                    input={input}
                    handleEnglishInputChange={handleEnglishInputChange}
                    handleArabicInputChange={handleArabicInputChange}
                    handleOptionalChange={handleOptionalChange}
                    handleDeleteInput={handleDeleteInput}
                    index={index}
                    sort={input.name}
                  />
                </>
              ))}

            <>
              {dropdownInputs.length > 0 &&
                dropdownInputs.slice()
                .sort((a, b) => {
                  const indexA = parseInt(a.name.split('-')[2]);
                  const indexB = parseInt(b.name.split('-')[2]);
                  return indexA - indexB;
                })
                .map((input, index) => (
                  <>
                    <DropdownField
                      input={input}
                      handleEnglishInputChange={handleEnglishInputChange}
                      handleArabicInputChange={handleArabicInputChange}
                      handleOptionalChange={handleOptionalChange}
                      handleDeleteInput={handleDeleteInput}
                      index={index}
                      sort={input.name}
                    />
                    <>
                      {input.options.map((option) => (
                        <DropdownOption
                          input={option}
                          parentID={input.id}
                          handleEnglishInputChange={handleEnglishInputChange}
                          handleArabicInputChange={handleArabicInputChange}
                          handleOptionalChange={handleOptionalChange}
                          handleAddInput={handleAddInput}
                          handleDeleteInput={handleDeleteInput}
                        />
                      ))}{" "}
                    </>
                    {/* {input.options.length > 0 && ( */}

                    {/* )} */}
                    {dropdownInputs.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          position: "relative",
                        }}
                        onClick={() =>
                          handleAddInput("dropdownInputOption", index)
                        }
                      >
                        <div
                          style={{
                            position: "absolute",
                            right: "170px",
                            bottom: "3px",
                          }}
                        >
                          <img src={addicon} alt="mySvgImage" />{" "}
                          <span
                            style={{
                              color: "rgb(17, 93, 252)",
                              fontWeight: "bold",
                            }}
                          >
                            Add
                          </span>
                        </div>
                      </div>
                    )}
                  </>
                ))}
            </>
            {arr.map((input, index) => {
              console.log({ input });
              return (
                <>
                  {input.type === "input" && (
                    <SingleInputField
                      input={input}
                      handleEnglishInputChange={handleEnglishInputChanges}
                      handleArabicInputChange={handleArabicInputChanges}
                      handleOptionalChange={handleOptionalChanges}
                      handleDeleteInput={handleDeleteInputs}
                      handleSetSingleInput={setSingalInputs}
                      singleInputs={singleInputs}
                      index={index}
                    />
                  )}

                  {input.type === "textarea" && (
                    <MultipleInputField
                      input={input}
                      handleEnglishInputChange={handleEnglishInputChanges}
                      handleArabicInputChange={handleArabicInputChanges}
                      handleOptionalChange={handleOptionalChanges}
                      handleDeleteInput={handleDeleteInputs}
                      handleSetMultipleInput={setMultipleinput}
                      multipleInput={multipleInput}
                      index={index}
                    />
                  )}

                  {input.type === "dropdown" && (
                    <>
                      <DropdownField
                        input={input}
                        handleEnglishInputChange={handleEnglishInputChanges}
                        handleArabicInputChange={handleArabicInputChanges}
                        handleOptionalChange={handleOptionalChanges}
                        handleDeleteInput={handleDeleteInputs}
                        handleSetDropdownInput={setDropdownInputs}
                        dropdownInput={dropdownInputs}
                        index={index}
                      />

                      {input.options.map((option) => (
                        <DropdownOption
                          input={option}
                          parentID={input.id}
                          handleEnglishInputChange={handleEnglishInputChanges}
                          handleArabicInputChange={handleArabicInputChanges}
                          handleOptionalChange={handleOptionalChanges}
                          handleAddInput={handleAdd}
                          handleDeleteInput={handleDeleteInputs}
                        />
                      ))}
                      {arr.length > 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            position: "relative",
                          }}
                          onClick={() =>
                            handleAdd("dropdownInputOption", index)
                          }
                        >
                          <div
                            style={{
                              position: "absolute",
                              right: "113px",
                              bottom: "3px",
                            }}
                          >
                            <img src={addicon} alt="mySvgImage" />{" "}
                            <span
                              style={{
                                color: "rgb(17, 93, 252)",
                                fontWeight: "bold",
                              }}
                            >
                              Add Options
                            </span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            })}

            <FormAddSectionButton
              handleAddInput={handleAdd}
              handleSetIsTone={setIsTone}
              handleSetIsLength={setIsLength}
              handleSetIsImage={setIsImage}
            />

            <Form.Group className="col-md-12">
              <Form.Label>Prompt</Form.Label>
              <Form.Control
                ref={inputRef}
                as="textarea"
                rows={8}
                placeholder="Enter Prompts"
                label="prompt"
                id="prompt"
                required
                name="prompt"
                onChange={handleChange}
                value={newData.prompt}
                style={{ whiteSpace: "pre-wrap" }}
              />
            </Form.Group>

            <div className="mt-3">
              {isTone && (
                <>
                  <Form.Check
                    type={"checkbox"}
                    id={`default-"checkbox"`}
                    checked={isToneChecked}
                    label={`Tone [TONE]`}
                    onChange={() => setIsToneChecked(!isToneChecked)}
                  />{" "}
                  <br />
                </>
              )}
              {isLength && (
                <>
                  <Form.Check
                    type={"checkbox"}
                    id={`default-"checkbox"`}
                    checked={isLengthChecked}
                    label={`Length [LENGTH]`}
                    onChange={() => setIsLengthChecked(!isLengthChecked)}
                  />{" "}
                  <br />
                </>
              )}
              {isImage && (
                <>
                  <Form.Check
                    type={"checkbox"}
                    id={`default-"checkbox"`}
                    checked={isImageChecked}
                    label={`Image [IMAGE]`}
                    onChange={() => setIsImageChecked(!isImageChecked)}
                  />
                  <Form.Check
                    type={"checkbox"}
                    id={`default-"checkbox"`}
                    checked={isImageOptionalChecked}
                    label={`is image optional`}
                    onChange={() =>
                      setIsImageOptionalChecked(!isImageOptionalChecked)
                    }
                  />{" "}
                  <br />
                </>
              )}

              {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                {singleInputs.length > 0 && <h5>SINGLE INPUT</h5>}
                <div style={{ display: "flex", gap: "7px" }}>
                  {singleInputs.length > 0 &&
                    singleInputs.map((input, index) => {
                      return (
                        <Button
                          variant="primary"
                          onClick={(e) =>
                            handleChange({
                              target: {
                                name: "promptType",
                                value: input.type,
                                id: index,
                              },
                            })
                          }
                        >
                          [SINGLE-INPUT-{index + 1}]
                        </Button>
                      );
                    })}
                </div>
              </div>
              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                {multipleInput.length > 0 && <h5>DYNAMIC INPUT</h5>}
                <div style={{ display: "flex", gap: "7px" }}>
                  {multipleInput.length > 0 &&
                    multipleInput.map((input, index) => {
                      return (
                        <Button
                          variant="secondary"
                          onClick={(e) =>
                            handleChange({
                              target: {
                                name: "promptType",
                                value: input.type,
                                id: index,
                              },
                            })
                          }
                        >
                          [DYNAMIC-INPUT-{index + 1}]
                        </Button>
                      );
                    })}
                </div>
              </div>

              <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                {dropdownInputs.length > 0 && <h5>DROPDOWN INPUT</h5>}
                <div style={{ display: "flex", gap: "7px" }}>
                  {dropdownInputs.length > 0 &&
                    dropdownInputs.map((input, index) => {
                      return (
                        <Button
                          variant="success"
                          onClick={(e) =>
                            handleChange({
                              target: {
                                name: "promptType",
                                value: input.type,
                                id: index,
                              },
                            })
                          }
                        >
                          [DROPDOWN-INPUT-{index + 1}]
                        </Button>
                      );
                    })}
                </div>
              </div> */}

              {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                <div style={{ display: "flex", flexWrap:"wrap", gap: "7px" }}>
                  {arr.length > 0 &&
                    arr.map((input, index) => {
                      return (
                        <Button
                          variant="success"
                          onClick={(e) =>
                            handleChange({
                              target: {
                                name: "promptType",
                                value: input.type,
                                id: index,
                              },
                            })
                          }
                        >
                         
                          {input.type === "input" && (
                            <p style={{margin:0}}>{`SINGLE-INPUT-${index + 1}`}</p>
                          )}{" "}
                          {input.type === "textarea" && (
                            <p style={{margin:0}}>{`DYNAMIC-INPUT-${index + 1}`}</p>
                          )}
                          {input.type === "dropdown" && (
                            <p style={{margin:0}}>{`DROPDOWN-INPUT-${index + 1}`}</p>
                          )}
                        </Button>
                      );
                    })}
                </div>
              </div> */}

              {/* <div
                className="d-flex gap-0"
                style={{
                  marginTop: "-68px",
                  position: "absolute",
                  marginLeft: "364px",
                }}
              >
                <button
                  style={{
                    padding: "3px",
                    borderStartStartRadius: "0.475rem",
                    borderEndStartRadius: "0.475rem",
                    border: "1px solid #e4e6ef",
                  }}
                  className={` ${inputButtonClicked ? "" : ""}`}
                  onClick={() =>
                    handleChange({
                      target: { name: "promptType", value: "input" },
                    })
                  }
                  disabled={inputButtonClicked}
                >
                  Input
                </button>
                <button
                  style={{
                    padding: "3px",
                    borderStartEndRadius: "0.475rem",
                    borderEndEndRadius: "0.475rem",
                    border: "1px solid #e4e6ef",
                  }}
                  className={` ${toneButtonClicked ? "" : ""}`}
                  onClick={() =>
                    handleChange({
                      target: { name: "promptType", value: "tone" },
                    })
                  }
                  disabled={toneButtonClicked}
                >
                  Tone
                </button>
              </div> */}

              {/* //multiple time input select code */}
              {/* <div className="d-flex gap-3"
              style={{marginTop:'-68px',position:'absolute',marginLeft:'352px'}}
               >
                <button  
                 style={{    
                 padding:'3px'
                }}      
                  onClick={() => handleChange({ target: { name: 'promptType', value: 'input' } })}
                >
                  Input
                </button>
                <button

                 style={{
                  padding:'3px'
                }}
                  onClick={() => handleChange({ target: { name: 'promptType', value: 'tone' } })}
                >
                  Tone
                </button>
              </div> */}

              {/* //radio button code */}
              {/* <div className="d-flex gap-5" style={{float:'right',marginTop:'-60px',marginRight:"15px"}}>
                <Form.Check
                  type="radio"
                  id="inputRadio"
                  name="promptType"
                  value="input"
                  label="Input"
                  checked={newData.promptType === "input"}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  id="textRadio"
                  name="promptType"
                  value="tone"
                  label="tone"
                  checked={newData.promptType === "tone"}
                  onChange={handleChange}
                />
              </div> */}
            </div>

            {/* <Form.Group className="col-md-12">
              <Form.Label>Contact No.</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter contact no"
                label="contact"
                id="contact"
                required
                name="contact"
                onChange={handleChange}
                value={newData.contact}
              />
              <span className="errorInput">
             
              </span>
            </Form.Group> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isEdit ? (
            <>
            <button className="btn btn1 btn-color" disabled={updateBtnBool} onClick={onUpdate}>
              Update Changes
            </button>
            </>
          ) : (
            <button disabled={createBtnBool} className="btn btn1 btn-color" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </Modal.Footer>
      </Modal>
      <Modal
        show={viewModal}
        onHide={() => setViewModal(false)}
        centered
        className="modal-width"
      >
        <Modal.Header closeButton>
          <Modal.Title>Tasks Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {/* <div className="text-center" style={{ marginBottom: "1.75rem" }}>
              <img
                src={
                  viewData?.image
                    ? viewData?.image
                    : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png";
                }}
                alt="userImage"
                className=" rounded-pill object-cover"
                height="100px"
                width="100px"
              />
            </div> */}
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Task Name</div>
              <div className="col-md-7">{newData?.categories ?? "-"}</div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Task Name (AR)</div>
              <div className="col-md-7">{newData?.translaName ?? "-"}</div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Category</div>
              <div className="col-md-7 text-capitalize">
                {newData?.getcategories ?? "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">prompt</div>
              <div className="col-md-7 text-capitalize">
                {newData?.prompt ?? "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Description</div>
              <div className="col-md-7 text-capitalize">
                {newData?.description ?? "-"}
              </div>
            </div>
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Description (AR)</div>
              <div className="col-md-7 text-capitalize">
                {newData?.translaDescriptions ?? "-"}
              </div>
            </div>

            {/* 
            <div className="row py-2">
              <div className="col-md-5 fw-bold">Email</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.email ?? "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">DOB</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.dob
                  ? moment(viewData?.createdAt).format("DD-MM-YYYY")
                  : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Gender</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.gender === 0 ? "Male" : "Female"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">About Me</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.aboutMe ? viewData?.aboutMe : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Hobby</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.hobby ? viewData?.hobby : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Relationship Status </div>
              <div className="col-md-7 text-capitalize">
                {viewData?.relationshipStatus
                  ? viewData?.relationshipStatus === 0
                    ? "Married "
                    : viewData?.relationshipStatus === 1
                      ? "Single"
                      : "Relationship"
                  : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Shoe Size</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.ShoeSize ? viewData?.size?.ShoeSize : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Bust</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.Bust ? viewData?.size?.Bust : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Shirt Size</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.ShirtSize ? viewData?.size?.ShirtSize : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Dress</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.Dress ? viewData?.size?.Dress : "-"}
              </div>
            </div>

            <div className="row py-2">
              <div className="col-md-5 fw-bold">Waist</div>
              <div className="col-md-7 text-capitalize">
                {viewData?.size?.Waist ? viewData?.size?.Waist : "-"}
              </div>
            </div>
             */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn1" onClick={() => setViewModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
