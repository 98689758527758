import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
const FormAddSectionButton = (props) => {
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ position: "relative" }} ref={dropdownRef}>
      <hr />
      <Button
        variant="secondary"
        onClick={() => setIsShowDropdown(!isShowDropdown)}
        style={{marginTop:".5rem", marginBottom:".5rem"}}
      >
        + Add Form / Section
      </Button>
      {isShowDropdown && (
        <ul
          style={{
            listStyle: "none",
            position: "absolute",
            background: "white", // Changed background to white
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Added box shadow
            zIndex: 3,
            padding: "1rem",
            margin: 0,
            width: "max-content",
          }}
        >
          <li
            style={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => props.handleAddInput("singleInput")}
          >
            Add single input form
          </li>
          <li
            style={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => props.handleAddInput("multipleInput")}
          >
            Add dynamic input form
          </li>
          <li
            style={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => props.handleAddInput("dropdownInput")}
          >
            Add dropdown form
          </li>
          <li
            style={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => props.handleSetIsImage(true)}
          >
            Add image form
          </li>
          <li
            style={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => props.handleSetIsTone(true)}
          >
            Add tone form
          </li>
          <li
            style={{
              marginBottom: "0.5rem",
              marginTop: "0.5rem",
              cursor: "pointer",
            }}
            onClick={() => props.handleSetIsLength(true)}
          >
            Add length form
          </li>
        </ul>
      )}
    </div>
  );
};

export default FormAddSectionButton;
